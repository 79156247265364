import React from 'react';

export const STEP_CONTEXT: { [key: number]: JSX.Element } = {
  1: (
    <>
      <h2>LinguaLift Method</h2>
      <p>
        We teach language for the real world through themed, interactive lessons designed to get you
        using the language from day one.
      </p>
    </>
  ),
  2: (
    <>
      <h2>Tutors</h2>
      <p>
        We’re here to answer questions and correct homework. Just send us a message, and the most
        qualified person will answer your question!
      </p>
    </>
  ),
  3: (
    <>
      <h2>Remember what you learn</h2>
      <p>
        Spaced repetition is one of the most valuable language learning tools there is. Our program
        will actually tell you when you need to review!
      </p>
    </>
  ),
  4: (
    <>
      <h2>Leaderboards</h2>
      <p>
        Earn points to stay on top of the leaderboard and secure your place in the Grand Master
        league.
      </p>
    </>
  ),
  5: (
    <>
      <p>
        Are looking for a simple game that tries to teach
        <br />
        you a language? <br />
        Well…..that’s not us!
      </p>
      <p>
        Becoming fluent in a language takes hard work,
        <br />
        dedication, and a great <br />
        learning system.
      </p>
      <p>
        But don’t worry. We give you the plan, the tools, and guide you every step <br />
        of the way.
      </p>
    </>
  ),
};
