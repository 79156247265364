import './App.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Notification from './components/Notification/Notification';
import AppModal from './features/organisms/AppModal/AppModal';
import AllRoutes from './routes/routes';
import { getIsAuthenticated } from './store/selectors/authSelectors';
import { getUser, setIsFetchSuccess } from './store/slices/auth';
import { getLanguagesData } from './store/slices/study';
import { getToken, setToken } from './utils/auth';

const App = () => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (document.URL.includes('tap_')) {
      const copyToClipboard = () => {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            console.log('URL copied to clipboard');
          })
          .catch((error) => {
            console.error('Failed to copy URL: ', error);
          });
      };

      if (navigator.clipboard) {
        navigator.permissions
          .query({ name: 'clipboard-write' as PermissionName })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'granted') {
              copyToClipboard();
            } else if (permissionStatus.state === 'prompt') {
              console.log('Permission to access clipboard not yet granted');
            } else {
              console.error('Permission to access clipboard denied by user');
            }
          })
          .catch((error) => {
            console.error('Failed to request permission to access clipboard: ', error);
          });
      } else {
        console.error('Clipboard API not available');
      }
    }
    const token = getToken();
    if (!isAuthenticated && hash && hash.includes('access_token=')) {
      const token = hash.split('&')[0].slice(14);
      dispatch(getUser());
      setToken(token);
    } else if (token) {
      dispatch(getUser(true));
    } else {
      dispatch(setIsFetchSuccess(true));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getLanguagesData());
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <AppModal />
      <Notification />
      <AllRoutes />
    </div>
  );
};

export default App;
